.services {
  /* padding: 110px 0; */
}

.over {
  z-index: 3;
}

.services .text_services {
  background-color: #FFFFFF;
  width: 100%;
  padding-top: 40px;
}


.hidden {
  opacity: 0;
  transform: translateY(-100%);
  transition: transform .5s ease-in;
}

.fixed_container {
  position: fixed;
  z-index: 99;
  transition: transform .5s ease-in-out;
  opacity: 1;
}

.services .text_services h1,
.services .text_services p {
  margin: 0px auto;
  text-align: center;
  width: 700px;
}


.services .text_services p {
  padding-top: 30px;
  padding-bottom: 40px;
}

.services_box {
  margin: auto;
  width: 1110px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.services>.scroll {
  height: 800px;
  overflow-y: scroll;
}

.services>.scroll::-webkit-scrollbar {
  display: none;
}

.services>.scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@media (max-width: 768px) {
  .services {
    padding: 0px;
    margin: 90px auto 30px;
  }

  .services .text_services {
    padding-bottom: 0px;
    padding-top: 20px;
  }

  .text_services h1,
  .text_services p {
    max-width: 308px;
  }

  .services .text_services p {
    padding-bottom: 20px;
}

  .services_box {
    max-width: 308px;
  }

}
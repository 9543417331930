.answers{
    margin-top: 30px;
    margin-bottom: 120px;
}

.answers>.row {
    display: flex;
    align-items: center;
}

.answers>.row>h1 {
    max-width: 500px;
    margin-right: 30px;
}
.answers>.description {
    margin-bottom: 40px;
}

.answers>.description>p {
    font-size: 18px;
    line-height: 31px;
}

.answers>.row>.button_light {
    max-height: 50px;
    background-color: #E9ECF0;
    color: #151C45 !important;
    font-size: 14px !important;
    text-transform: capitalize;
    font-weight: normal;
    padding: 10px 30px;
    border-radius: 10px;
}

.answers>.row>.button_light>img {
    width: 17px;
    margin-right: 5px;
}


.answers>.horizontal_box {
    min-height: 120px;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 10px 30px;
    /* display: flex;
    align-items: center; */
}

.answers>.horizontal_box> .content>h3 {
    font-size: 18px;
}

.answers>.horizontal_box >.content>p {
    font-size: 16px;
    color: #151C45;
    text-align: justify;
    max-width: 990px;
    line-height: 25px;
}


@media (max-width: 768px) {
    .answers {
        padding: 0px 30px;
    }

    .answers>.row {
        margin-top: 10px;
    }

    .answers>p {
        text-align: justify;
    }

    .answers>.horizontal_box {
        min-height: 250px;
    }

}
.payments {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
    padding-top: 160px;
}

.payment_wrap {
    max-width: 970px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payment_left {
    padding-left: 30px;
}

.payment_rigth {
    max-width: 500px;
    margin-top: 15px;
    margin-bottom: 140px;
}

.payment_rigth>.section_description {
    max-width: 420px;
}

.boxes {
    padding-top: 45px;
}

.paying_wrap {
    max-width: 1220px;
    margin: 0 auto;
    margin-right: -20px;
}

.paying {
    width: 371px;
    float: right;
    margin-top: -205px;
    margin-bottom: 100px;
    position: relative;
}

.app_box {
    width: 359px;
}


@media (max-width: 768px) {
    .payments {
        padding-top: 45px;
        max-width: 300px;
    }

    .payment_rigth {
        max-width: 300px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 145px;
    }


    .payment_rigth >.payment_screen {
        margin-top: 40px;
    }

    .paying_wrap {
       max-width: 300px;
       margin-right: 15%;
    }

    .paying {
        width: 220px;
        height: 198px;
        margin-top: -140px;
        margin-bottom: 50px;
    }

    .app_box {
        width: 268px;
    }

}
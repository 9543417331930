.dashboard {
    background-color: #F1F6F6;
}

.dashboard .content {
    padding: 120px 20px 150px;
    width: 1110px;
    margin: auto;
}

.dashboard .content .row {
    display: flex;
    justify-content: space-between;
}

.dashboard .content .row .section_subtitle {
    margin-top: 85px;
}

.dashboard .dashboard_img {
    max-width: 630px;
}


@media (max-width: 768px) {
    .dashboard .content .row {
        flex-direction: column;
    }

    .dashboard .dashboard_img {
        max-width: 300px;
        margin: 35px auto;
    }

    .service_title {
        text-align: center;
    }

    .dashboard .content {
        width: 308px;
        padding: 0px 20px;
    }

    .dashboard .left {
        text-align: center;
        padding-bottom: 100px;
    }

    .dashboard .right {
        padding-bottom: 40px;

    }

    .dashboard h1 {
        max-width: 617px;
        text-align: center;
        margin: auto;
    }

}
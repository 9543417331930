.prices_background {
    position: relative;
}

.prices_background .back_dots {
    width: 200px;
    position: absolute;
    z-index: -1;
}

.prices_background .vertical_dots {
    transform: translate(-100px, -45px);

}

.prices_background .horizontal_dots {
    transform: translate(-130px, 385px) rotate(90deg);
    right: 0px;
}

.middle_color {
    border-top-right-radius: 900px 60px;
    background: linear-gradient(#E5F1F7, #FFFFFF);
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -100;
    top: 400px;
}

.prices {
    max-width: 1110px;
    margin: 80px auto;
}

.prices .prices_intro {
    margin: 0px auto;
    text-align: center;
    width: 510px;
    margin-bottom: 120px;
}

.cardbox_container {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .prices {
        max-width: 300px;
        margin: 60px auto;
    }

    .prices .prices_intro {
        max-width: 308px;
        margin-bottom: 60px
    }

    .middle_color {
        border-top-right-radius: 0px 0px;
        top: 350px;
    }


}
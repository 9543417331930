.card {
  background-color: #FFFFFF;
  width: 250px;
  height: 264px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.card:hover {
  margin-top: -10px;
  box-shadow: 0px 52px 54px #DDDDDD;
  transition: margin 0.2s ease-in-out;
}

.content_card {
  margin: auto 20px;
  padding-top: 24px;
}

.icon_wrap {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_icon {
  max-height: 25px;
  max-width: 25px;
}

.card_title_wrap {
  margin-top: 15px;
}

.card_title {
  font-size: 21px;
  font-weight: bold;
}

.card_text_wrap {
  margin-top: 15px;
}

.card_text {
  font-size: 16px;
  line-height: 28px;
  color: #696871;
  letter-spacing: -0.5px;
}

@media (max-width: 768px) {
  .card:hover {
    margin-top: 0px;
    box-shadow: 0px  0px 0px ;
  }
}
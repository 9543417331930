.benefits_wrap {
  max-width: 1060px;
  margin: 0 auto;
  padding: 0 70px;
  display: flex;
  justify-content: space-around;
  padding-top: 115px;
  padding-bottom: 92px;
}

.benefits_cards {
  width: 530px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.benefits_text_button {
  max-width: 520px;
  padding-top: 60px;
}

.benefits_text_button .section_subtitle {
  margin-top: 0px;
}


.benefits_description {
  font-size: 23px;
  line-height: 39px;
}

.wrap_button_download {
  margin-top: 63px;
}

.benefits_right {
  padding-left: 55px;
}

.crane_wrap {
  max-width: 1340px;
  margin: 0 auto;
  margin-bottom: 170px;
}

.benefit_crane {
  width: 625px;
  height: 270px;
  float: right;
  margin-right: 162px;
  margin-bottom: 130px;
  margin-top: -135px;
}

@media (max-width: 768px) {

  .benefits_wrap {
    flex-direction: column-reverse;
    padding: 0 15px;
    border-bottom-right-radius: 0px;
    padding-bottom: 101px;
  }

  .benefits_text_button {
    text-align: center;
    margin: 0 auto;
    max-width: 335px;
    display: flex;
  }

  .benefits_right {
    padding-left: 0px;
  }

  .benefits_text {
    margin-bottom: 40px;
  }

  /* .benefits_title {
    font-size: 30px;
    margin-top: 0px;
    line-height: 38px;
    text-align: center;
  } */

  /* .section_description {
    font-size: 17px;
    text-align: center;
    line-height: 30px;
  } */

  .benefits_cards {
    width: 93vw;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .content_card {
    width: 200px;
  }

  .card {
    margin-right: 15px;
  }

  .dots {
    width: 11.48px;
    height: 11.48px;
    margin-left: 5.5px;
  }

  .benefits_mobile_slider {
    width: 70px;
    margin: auto;
  }

  .crane_wrap {
    max-width: 325px;
    margin-top: -66px;
  }

  .benefit_crane {
    width: 100%;
    height: 134px;
    margin: auto;
    margin-bottom: 70px;
  }


}
.alert_service {
  padding: 0 20px;
}

.alert_service>.row {
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  margin-top: 50px;
}

.alert_service>.row>.left {
  width: 522px;
}

.alert_service .screens_app {
  height: 560px;
}

.alert_title {
  display: flex;
  align-items: center;
}

.alert_title>img {
  height: 26px;
  margin-left: 15px;
}

.alert_service .left .section_description {
  width: 500px;
}

.alert_service>.row>.left>.list {
  margin-top: 40px;
  max-width: 450px;
}

.alert_service>.row>.left>.buttons {
  max-width: 480px;
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
}

.modal_box {
  width: 450px;
  background-color: #FFFFFF !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 34px;
  box-shadow: 0px 3px 6px #0000002e;
  display: flex;
  justify-content: center;
}

.modal_box .modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding-bottom: 30px;
}

.modal_box .modal {
  color: #151C45;
}

.modal_box .modal-title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin: 35px auto;
  justify-content: center;
  width: 250px;

}

.modal_box .price_box>div {
  width: 90%;
  margin: 0px auto;
  margin-top: 10px;
}

.reserved {
  color: #B2BAC6;
  font-size: 9px;
}

@media (max-width: 768px) {
  .alert_service>.row {
    max-width: 300px;
  }

  .alert_service .left .section_description {
    width: 300px;
  }

  .alert_service>.row {
    flex-direction: column;
    margin-top: 15px
  }

  .alert_service>.row>.left {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .alert_service>.row>.left>.list {
    margin-top: 30px;
  }

  .alert_service>.row>.left>.buttons {
    flex-direction: column;
    margin: 30px 0px;
  }

  .alert_service .right {
    text-align: center;
    margin-top: 20px;
  }

  .screens_app_wrap{
    min-height: 300px;
  }


  .alert_service .screens_app {
    height: 300px;
  }

  .alert_service .screens_app {
    margin: 20px auto;
  }

  .alert_service .modal .close_modal {
    background: beige;
    position: relative;
  }

  .close_modal img {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 5px;
  }


  .modal_box {
    width: 350px;
    border-radius: 0px;
  }

  .modal_box .modal-title {
    font-size: 20px;
    margin: 30px auto ;
  }

  .modal_box .modal_container {
    width: 250px;
    padding-bottom: 0px;
  }

}
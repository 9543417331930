.service_info {
    padding: 0 20px;
    padding-bottom: 100px;
}

.service_info>.service_info_container {
    max-width: 1100px;
    margin: 0px auto;
    margin-top: 120px;
    justify-content: space-around;

}

.service_info .row {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.service_info .header_services {
    display: flex;
    align-items: center;
}

.header_services img {
    height: 30px;
    margin-right: 15px;
}

.service_info>.service_info_container>.section_description {
    display: flex;
    width: 500px;
}

.service_info .service_box img {
    width: 55px;
}


.service_info .left .column_box {
    max-width: 400px;
}

.service_info .right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service_info .no_visible {
    display: none;
}



.service_info .right img {
    height: 420px;
    margin-top: 25px;
}


.service_info .right .description {
    font-size: 16px;
    line-height: 25px;
    color: #151C45;
    max-width: 500px;
    text-align: center;
    margin: 20px auto 0;
}

@media (max-width: 768px) {
    .service_info {
        padding-bottom: 50px;
    }

    .service_info>.service_info_container {
        margin-top: 0px;
        max-width: 300px;
    }

    .service_info .header_services {
        justify-content: center;
    }

    .service_info>.service_info_container>.section_description {
        max-width: 300px;
    }


    .service_info .row {
        margin-top: 30px;
    }

    .service_info .no_visible {
        display: none;
        /* transition: all .5s ease-in; */
    }

    .service_info .visible {
        display: block;
        /* flex-direction: column;
        align-items: center; */
        /* transition: all .5s ease-out; */
    }

    .service_info .service_description img {
        max-height: 180px;
        margin-top: 15px;
    }

}
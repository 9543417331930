.info_box {
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 413px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.box_icon {
    width: 55px;
    margin: 20px;
}

.title_box {
    font-size: 18px;
    font-weight: bold;
}

.description_box {
    font-size: 17px;
    color: #696871;
}

@media (max-width: 768px) {
    .info_box {
        width: 309px;
        height: 71px;
    }

    .box_icon {
        width: 41px;
        margin: 15px;
    }

    .info_box span {
        font-size: 16px;
    }

}
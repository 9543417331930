.price_info {
    width: 100%;
    margin-bottom: 35px;
}

.header_box {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    margin: 10px 10px;
}

.header_box .title_box {
    font-size: 12px;
    font-weight: bold;
}

.header_box .subtitle_box {
    font-size: 12px;
}

.price_box {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #0000002e;
    width: 100%;
    font-size: 10px;
    padding: 5px 0px 15px;
}

.price_box img {
    height: 13px;
    margin-right: 10px;
}


.price_box .price {
    font-size: 11px;
    font-weight: bold;
    color: #3880AD;

}

.price_box .row,
.price_box .item_list {
    display: flex;
    align-items: center;
}

.price_box .item_list {
    justify-content: space-between;
    border-bottom: 1px solid;
    border-bottom-color: #E2E2E2;
    padding: 5px 5px;
}


.price_box .total {
    background-color: #ECF0F5;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    padding: 4px 5px;

}

.price_box .total span {
    margin-left: 10px;
}


.price_box .additional_text {
    margin-top: 10px;
}

.price_box .additional_text .price {
    margin-top: 10px;
    font-size: 10px;
    margin: 5px;
}

.complement {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
    font-size: 9px;
    color: #AAAAAA;
}
.question_box {
    margin-bottom: 30px;
    max-width: 950px;
    height: 120px;
    background-color: #FFFFFF;
    align-items: center;
    padding: 0px 63px;
    justify-content: space-between;
    border-radius: 10px;
    cursor: pointer;
}

.question_box {
    display: flex;
}


.question_box>.icon img {
    width: 80px;
}

.question_box>.color img {
    width: 65px;
}


.question_box>.column {
    display: flex;
    flex-direction: column;
    width: 750px;
}

.question_box>.column>h4 {
    margin: 0px;
    font-size: 20px;
    color: #19191B;
}

.question_box>.column>p {
    margin: 5px 0px;
    font-size: 16px;
    color: #151C45;
}

.question_box>.column>span {
    margin-top: 10px;
    font-size: 12px;
    color: #2495B3
}

.question_box>.arrow img {
    width: 10px;
    height: 18px;
}

@media (max-width: 768px) {
    .question_box {
        max-width: 350px;
        padding: 0px 20px;
        margin-bottom: 20px;
    }

    .question_box>.column {
        max-width: 200px;
    }

    .question_box>.icon img {
        width: 60px;
    }

    .question_box>.color img {
        width: 50px;
    }

    .question_box>.column>h4 {
        font-size: 18px;
    }

    .question_box>.column>span {
        margin-top: 10px;
    }

    .question_box>.arrow img {
        width: 7px;
        height: 15px;
    }







}
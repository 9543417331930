.insurance .content {
    padding: 80px 20px 100px;
    width: 1110px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.insurance .section_subtitle {
    text-align: center;
}

.insurance .section_description {
    max-width: 700px;
    text-align: center;
}

.insurance .content img {
    margin-top: 50px;
    max-width: 800px;
}


@media (max-width: 768px) {
    .insurance .content {
        max-width: 308px;
        padding-bottom: 80px;
    }

    .insurance .content img{
        max-width:350px;
    }

    
}
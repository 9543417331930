* {
  font-family: "Roboto", sans-serif;
}

.container {
  max-width: 1100px;
  margin: auto;
  padding: 0 20px;
}


.mobile {
  display: none !important;
}

@media (max-width: 768px) {
  html, body {
    overflow-x: hidden;
  }

  body {
    position: relative
  }
  .desktop {
    display: none !important;
  }
  .mobile{
    display: block !important;
  }

}
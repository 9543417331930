.wrapper_stores {
    display: flex;
    width: 325px;
    justify-content: space-between;
}

.wrapper_stores>.logos {
    width: 150px;
    height: 50px;
    cursor: pointer;

}
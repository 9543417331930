.notifications {
    max-width: 1100px;
    margin: auto;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 150px;
}

.notifications_left {
    max-width: 570px;
    margin-top: 35px;
}

.text_notifications > .section_description {
    margin-bottom: 60px;
}

.policeman {
    width: 520px;
    height: 292px;
    margin-left: 35px;

}

@media (max-width: 768px) {
    .notifications {
        flex-direction: column;
        margin-bottom: 75px;
    }

    .notifications_left {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
    }

    .policeman {
        height: 170px;
        width: 310px;
        margin: auto;
    }

    .alert_screen {
        max-width: 345px;
        margin: auto;
    }

    .text_notifications {
        margin-top: 70px;
    }
}
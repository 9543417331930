.managment_service .content {
  padding: 100px 20px 80px;
  width: 1110px;
  margin: auto;
}

.managment_service .section_subtitle {
  max-width: 500px;
  text-align: center;
  margin: auto;
}

.managment_service .box {
  max-width: 1110px;
  background-color: #FFFFFF;
  padding: 70px;
  border-radius: 30px;
  margin-top: 50px;
  box-shadow: 0px 3px 6px #DDDDDD;
}

.managment_service .box .row {
  display: flex;
  justify-content: space-around;
}

.managment_service .left img {
  max-height: 450px;
}

.managment_service .section_description {
  max-width: 470px;
}

.managment_service button {
  margin-top: 50px;
}

.managment_service .right {
  display: flex;
  flex-direction: column;
}

.managment_service .right .price {
  font-size: 45px;
  font-weight: bold;
  color: #304872;
  margin-top: 15px;
}

.box {
  transition: all 0.3s ease-out;
}

.opened {
  display: block;
}

.closed {
  display: none;
}

.managment_service .small {
  font-size: 15px;
}

.managment_service .list {
  margin: 0 auto;
  max-width: 960px;
  margin-top: 40px;
}

.managment_service .note {
  max-width: 615px;
}

@media (max-width: 768px) {
  .managment_service .content {
    max-width: 308px;
    padding-top: 80px;
  }

  .managment_service .box .row {
    flex-direction: column;
  }

  .managment_service .left img {
    max-height: 280px;
  }

  .managment_service .box {
    padding: 20px;
  }

  .managment_service .right {
    align-items: center;
  }

  .managment_service .right .price {
    font-size: 34px;
  }

  .additional .icon_list_item {
    width: 100%;
  }

  .managment_service button {
    margin-top: 30px;
  }
}
.question_collapse {
    height: 60px;
    margin-bottom: 20px;
}


.question_collapse > .wrap {
    max-width: 950px;
    display: flex;
    justify-content: space-between;
    background-color: #DCE3ED;
    align-items: center;
    padding: 0px 63px;
    border-radius: 10px;
    cursor: pointer;
}

.question_collapse >.wrap  h3 {
    color: #151C45;
    font-size: 20px;
}
.question_collapse >.wrap > .arrow img{
    width: 10px;
    height: 18px;
}


@media (max-width: 768px) {

  .question_collapse > .wrap {
    padding: 0px 20px;
  }

  .question_collapse >.wrap  h3 {
    font-size: 18px;
}
}
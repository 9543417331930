.opinions {
    max-width: 1100px;
    margin: auto;
    padding: 0px 20px;
    margin-top: 150px;
    padding-bottom: 160px;
}

.opinions>.container_row {
    margin-top: 110px;
    display: flex;
    justify-content: space-around;
}

.opinions>h1 {
    max-width: 617px;
    text-align: center;
    margin: auto;
}


@media (max-width: 768px) {
    .opinions {
        padding-bottom: 0px;
        margin-top: 100px;
    }

    .opinions>.container_row {
        flex-direction: column;
        width: 307px;
        margin: auto;
        margin-top: 60px;
    }
}
.introduction {
  max-width: 1100px;
  margin: auto;
  padding: 0 20px;
  padding-top: 80px;
  padding-bottom: 110px;
}

.intro_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left_container {
  margin-top: 6px;
  margin-left: 15px;
}

/* .right_container {
  margin-right: 20px;
} */

.screen_outo {
  width: 480px;
  margin-top: -50px;
}

.into_message {
  margin-top: 205px;
  margin-left: -190px;
  height: 100px;
  position: absolute;
}

.subtitle_container>p {
  color: #151B46;
  font-size: 22px;
}

.description_container {
  max-width: 515px;
  margin-top: 30px;
}

.stores_container {
  height: 100px;
  display: flex;
  align-items: center;
  margin-top: 43px;
}

.logos_store {
  width: 291px;
  height: 43px;
}

.play_icon {
  height: 70px;
  margin-left: 26px;
  cursor: pointer;
}

.watch_video {
  font-weight: bold;
  font-size: 17;
  color: #151B46;
  cursor: pointer;
}

.intro_wrap>.left_container>.title {
  font-weight: 900;
  font-size: 75px;
  color: #151B46;
  margin: 10px 0;
  margin-top: 25px;
}

.companies {
  height: 86px;
  max-width: 1100px;
  padding: 0px 20px;
  margin: auto;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.company {
  filter: grayscale(100%);
  max-width: 200px;
}

.description_container_mobile,
.screen_container_mobile {
  display: none;
}


@media (max-width: 768px) {

  .introduction {
    margin-left: 0px;
    padding: 0px;
    padding-top: 25px;
  }

  .intro_wrap {
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .left_container {
    margin: 0px;
  }

  .king {
    height:460px;
  }

  .into_message {
    height: 70px;
    margin-top: 85%;
    margin-left: 48%;
  }

  .intro_wrap>.left_container>.title {
    margin: auto;
    width: 315px;
    font-size: 50px;
    letter-spacing: -0.61px;
  }

  .subtitle_container {
    max-width: 302px;
    margin: auto;
    margin-top: 20.5px;
    margin-bottom: 20px;
    text-align: center;

  }

  .subtitle_container>p {
    font-weight: 300;
    font-size: 23px;
    line-height: 32px;
  }

  .stores_container {
    justify-content: center;
    margin-bottom: 20px;
  }

  .stores_container {
    height: 64px;
    margin-top: 0px;
  }

  .play_icon {
    width: 64px;
    height: 64px;
    margin-left: 0px;
  }

  .description_container_mobile {
    max-width: 335px;
    margin: auto;
    display: block;
    text-align: center;
  }

  .description_mobile {
    font-size: 17px;
    line-height: 30px;
  }

  .screen_container_mobile {
    display: flex;
    padding-top: 45px;
    height: 460px;
  }

  .screen_container_mobile>.king {
    margin: auto;
  }


}
.legal .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.legal>.row {
    height: 94px;
}

.legal>.row div {
    padding: 15px 30px;
    margin-right: 10px;
}

.legal>.row>.row div {
    cursor: pointer;
}


.legal>.row>.row span {
    font-size: 16px;
    color: #151C45;
}

.legal>.row>div .active {
    background-color: #E9ECF0;
    border-radius: 10px;
}

.legal>.row>.update>span {
    color: #969696;
    font-size: 14px;
}

.text_container {
    background-color: #FFFFFF;
    padding: 60px 80px;
    border-radius: 30px;
    margin-bottom: 30px;
    height: 1659px;
    overflow-y: scroll;
    text-align: justify;

}

.text_container>h1 {
    font-size: 32px;
    color: #19191B;
    margin-top: 0px;
}

.text_container>div p {
    font-size: 18px;
    color: #5A5A5F;
    line-height: 36px;
}


.text_block ul {
    font-style: normal;
    font-size: 18px;
    color: #5A5A5F;
    line-height: 36px;
}

@media (max-width: 768px) {
    .legal>.row {
        padding: 20px 0;
        flex-direction: column-reverse;
        align-items: normal;
    }

    .legal>.row div {
        padding: 0px;
        margin: 0px;
    }
    
    .legal>.row>.row>div {
        margin: auto ;
        padding: 10px 10px;
        text-align: center;
        width: 140px;
    }

    .legal>.row>.row span {
        font-size: 16px;
    }

    .legal>.row>.update {
        text-align: end;
    }

    .legal>.row>.update>span {
        font-size: 14px;
        right: 0px;
    }

    .text_container {
        padding: 30px 20px;
        border-radius: 5px;
        height: 850px;
    }

    .text_container>h1 {
        font-size: 25px;
    }

    .text_container>div p {
        font-size: 17px;
        line-height: 30px;
    }

}
@keyframes modal_video {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes inner {
    from {
        transform: translate(0, 100px)
    }

    to {
        transform: translate(0, 0)
    }
}


.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    animation-name: modal-video;
    transition: opacity .3s ease-out;
}


.modal-video-body,
.inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}


.modal-video-body {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
}

.inner {
    padding: 10px 60px;
    box-sizing: border-box;
    align-items: center;
}

.modal-video-movie {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    animation-name: inner;
    transform: translate(0);
    transition: transform .3s ease-out;
}


.modal-video-movie iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal_close_btn {
    position: absolute;
    z-index: 10000000;
    cursor: pointer;
    top: -45px;
    width: 35px;
    height: 35px;
    border: none;
    background: transparent;
}

.modal_close_btn:before,
.modal_close_btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
}

@media (orientation: landscape) {
    .modal_close_btn {
        top: 0;
        right: -45px;
    }
}

.modal_close_btn:before {
    transform: rotate(45deg);
}

.modal_close_btn:after {
    transform: rotate(-45deg);
}


@media (max-width: 768px) {
    .inner {
        padding: 0px 5px;
    }

    .modal-video-movie iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .modal_close_btn {
        top: -40px;
        right: 0px;
    }
}
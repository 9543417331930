.wrap_download_banner {
    height: 67px;
}
.download_app_banner {
    background-color: #E7EAEF;
    height: 67px;
    position: fixed;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 3px 6px #d7d7d7;
    box-shadow: 0px 2px 3px #DDDDDD;
}

.hide {
    display: none;
}


.download_app_banner>.wrap {
    display: flex;
    justify-content: space-around;
    height: 100%;
}

.download_app_banner>.wrap>div {
    align-self: center;
}


.download_app_banner>.wrap>.close {
    margin: auto 7px;
}


.download_app_banner>.wrap>.close>img {
    width: 15px;
}

.download_app_banner>.wrap>.logo {
    width: 70px;
    display: flex;
    width: auto;
    margin-right: 15px;
}

.download_app_banner>.wrap>.logo>img {
    width: 53px;
    border-radius: 10px;
}

.download_app_banner>.wrap>.app_description {
    width: 180px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.download_app_banner>.wrap>.app_description>p {
    margin: 0px;
    font-weight: 500;
    font-size: 17px;
}

.download_app_banner>.wrap>.app_description>span {
    max-width: 150px;
    color: #707070;
    font-size: 10px;
}
.star_wrap {
    display: flex;
    padding-top:5px;
}
.star {
    width: 10px;
    height: 10px;
    margin-right: 2px;
}

/* .download_app_banner>.wrap>.download {
    margin-right: 10px;
} */

.download_app_banner>.wrap>.download>button {
    font-family: "Roboto", sans-serif !important;
   

    width: 73px;
    height: 28px;
    background-color: #C6CFDE !important;
    color: #4C586C !important;
    font-size: 10px !important;
    text-transform: unset !important;
    margin: auto 15px;
}



.column_list {
    margin-top: 20px;
}
.column_list > p {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
}

.column_list>ul {
    list-style: none;
    padding-left: 0px;
    margin-top: 43px;
}

.column_list>ul>li {
    font-size: 17px;
    color: #AAAAAA;
    margin-bottom: 20px;
    cursor: pointer;
}
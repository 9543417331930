.header_container {
  height: 94px;
  box-shadow: 0px 2px 3px #DDDDDD;
}

.header_container header {
  width: 100%;
  background-color: #ffffff;
  z-index: 100;
  top: 0px;
  position: fixed;
  
}


.fixed_position header {
  box-shadow: 0px 2px 3px #DDDDDD;
}

.header {
  max-width: 1160px;
  margin: auto;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 94px;
}

.logo_outo {
  height: 45px;
  margin-top: 5px;
}

.nav_menu {
  display: flex;
  align-items: center;
  margin-top: 18px;
}

.list_menu {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 0px;
}

.li_menu {
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
}

.li_menu > div{
  padding: 0 10px 10px ;
}


.menu_label {
  font-size: 15px;
  color: #151C45;
}

.menu_subfix {
  font-weight: bold;
  color: #0E8EA5;
}

.menu_button {
  display: none;
}

.MuiDrawer-paper {
  margin-top: 10px;
  border-top-left-radius: 20px;
}

.side_menu_title {
  font-family: "Roboto";
  font-size: 25;
  margin-left: 47px;
}

.side_menu_list {
  margin: 0 28.4px !important;
  height: 100%;
  cursor: pointer;
}


.selected {
  border-bottom: solid 3px #0E8EA5;
  margin-top: 3px;
}



@media (max-width: 768px) {
  .header_container {
    height: 75px;
  }

  .header {
    background: #FFFFFF;
    /* padding: 15px; */
    padding-left: 24px;
    padding-right: 30px;
    box-shadow: 0px 3px 6px #dddddd;
    height: 75px;
  }

  .downside {
    top: 67px !important ;
  }

  .logo_outo {
    width: 98px;
    height: 36px;
  }

  .menu_button {
    display: block;
  }

  .menu_icon {
    width: 28.31px;
    height: 18.88px;
  }

  .download_app {
    background-color: #0E8EA5;
  }

}
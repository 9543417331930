.border_box {
    display: flex;
    flex-direction: column;
    border-bottom: .5px solid #BEBEBE;
    /* width: 600px; */
    padding: 10px 15px;
}

.border_box_title {
    font-size: 21px;
    font-weight: 500;
    width: 540px;
    color: #19191B;
}

.border_box_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.border_box .border_box_text {
    width: 530px;
    font-size: 17px;
    line-height: 25px;
    display: none;
    color: #151C45;
    text-align: justify;
    margin-bottom: 30px;
}

.border_box .border_box_text p {
    margin-top: 0px;
}

.border_box .box_control {
    display: flex;
    height: 30px;
}

.border_box .open_border_box {
    display: block;
}

@media (max-width: 768px) {
    .border_box_title {
        width: 250px;
        font-size: 16px;
        text-align: left;
        line-height: 22px;
    }

    .border_box {
        padding: 10px;
        padding-right: 9px;
    }

    .border_box .box_control {
        height: 25px;
        margin-right: 0px;
        margin-left: 5px;
    }

    .border_box .border_box_text {
        width: 280px;
        margin-bottom: 20px;
    }

    .border_box .border_box_text p {
        margin-top: 10px;
        font-size: 16px;
    }


}
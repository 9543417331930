.text_container_conditions {
    background-color: #FFFFFF;
    padding: 60px 80px;
    margin-bottom: 30px;
    text-align: justify;
    margin-top: 10px;
}

.text_container_conditions>h1 {
    font-size: 32px;
    color: #19191B;
    margin-top: 0px;
}

.text_container_conditions>div p {
    font-size: 18px;
    color: #5A5A5F;
    line-height: 36px;
}


@media (max-width: 768px) {
    .text_container_conditions {
        padding: 30px 20px;
        border-radius: 5px;
        height: 850px;
        overflow-y: scroll;
        text-align: justify;
    }

    .text_container_conditions>h1 {
        font-size: 25px;   
         margin-bottom: 30px;
    }

    .text_container_conditions>div p {
        font-size: 17px;
        line-height: 30px;
    }

}
.questions {
    max-width: 1110px;
    margin: 80px auto;
    padding: 0 20px;
}

.question_wrapper {
    position: relative;
}

.question_background {
    background-image: linear-gradient(#FFFFFF, #E1E9ED);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleY(1.5);
}

.questions .text_services {
    margin: 0px auto;
    text-align: center;
}

.questions .row {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    /* min-height: 1100px; */
}

.questions .row .left {
    max-width: 400px;
    margin-top: 25px;
}

.questions .right {
    width: 650px;
}


.questions .no_visible {
    display: none;
}

.questions .collapse_box {
    background-color: #E8EFF2;
}

.questions .collapse_wrap .open_box {
    background-color: #FFFFFF;
}

.questions .collapse_wrap .collapse_icon {
    background-color: #D6E3E8
}

.questions .collapse_wrap .open_box .collapse_icon {
    background-color: #F4F5F7
}

@media (max-width: 768px) {
    .questions {
        margin: 60px auto;
        max-width: 300px;
    }

    .question_wrapper {
        padding: 0 20px;
    }

    .questions .row {
        margin-top: 0px;
        justify-content: center;
    }


    .question_background {
        background-image: none;
        position: absolute;
    }

    /* 
    
   


    .questions  .faqs_panel {

    } */
}
.icon_list_item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 50%;
}

.icon_list_item>span {
    margin-left: 13px;
    color: #151C45;
    font-size: 16px;
}

.icon_list_item>img {
    height: 20px;
}

.icon_list {
    display: flex;
    flex-wrap: wrap;
}
.opinion_card {
    width: 307px;
    height: 210px;
}

.opinion_header {
    display: flex;
}

.opinion_header>img {
    width: 56px;
    height: 56px;
    margin-right: 10px;
}

.opinion_header>div {
    display: flex;
    flex-direction: column;
}

.opinion {
    width: 300px;
    margin: auto;
    padding-top: 35px;
}

.person>.title {
    color: #19191B;
    font-size: 20px;
}
.opinion_card>.description>span {
    color: #696871;
    font-size: 20px;
}

.person>.title {
    font-weight: bold;
    margin-bottom: 7px;
}

.opinion_card .subtitle {
    font-size: 16px;
    color: #696871;
}

.opinion_card>.description>span {
    line-height: 39px;
    letter-spacing: -0.65646px;
}

.opinion_card>.description {
   max-width: 290px;
   margin-top: 20px;
}


@media (max-width: 768px) {
    .opinion_card {
        margin-bottom: 70px;
    }
}
.cardbox {
    width: 345px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #DDDDDD;
    border-radius: 30px;
    align-self: flex-start;
    height: 700px;
    position: relative;
}

.open_cardbox {
    height: auto;
}

.cardbox_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 40px;
}

.cardbox .title_row {
    display: flex;
    align-items: center;
}

.cardbox_plus {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.cardbox_plus .intro_plus {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: #E7E9F5;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50px);
    position: absolute;
    width: 100%;
}

.cardbox_plus>.intro_plus>span {
    color: #284975;
    font-size: 18px;
    font-weight: bold;
}

.label_price {
    position: absolute;
    right: -10px;
    background: #3D81AB;
    height: 27px;
    width: 150px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
}

.label_price span {
    color: #FFFFFF;
    font-size: 13px;
}


.cardbox .title_row img {
    height: 26px;
    margin-left: 15px;
}

.cardbox_content .price {
    font-weight: bold;
    font-size: 45px;
    color: #304872;
    margin-top: 25px;
    margin-left: 15px;
}

.cardbox_content .section_description {
    max-width: 231px;
    text-align: center;
}

.cardbox_content .small {
    font-size: 15px;
    margin-bottom: 40px;
}

.cardbox_content .list,
.cardbox_content .additional_list {
    margin-top: 30px;
}



.cardbox_content .list .icon_list_item,
.cardbox_content .additional_list .icon_list_item {
    width: 100%;
}

.cardbox .note {
    line-height: 26px;
    margin-top: 20px;
}

.cardbox .show_button {
    /* width: 100% !important; */
    border-radius: 28px !important;
    background-color: #DCE3ED !important;
    position: absolute;
    width: 265px !important;
    bottom: 50px;
    box-shadow: none;
    margin-top: 40px !important;
}

.open_cardbox .show_button {
    position: initial;
}

.cardbox .addition {
    color: #AD6A38;
    font-size: 24px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .cardbox_container {
        flex-direction: column;

    }
    .cardbox {
        width: 300px;
        height: auto;
        margin-bottom: 50px;
    }

    .cardbox_content {
        padding: 25px 30px;
    }

    .cardbox .show_button {
        position: initial;
        width: 100% !important;
    }

    .cardbox_plus {
        margin-top: 50px;
    }

    .cardbox_content .price {
        font-size: 34px;
        margin-top: 15px;
    }

    .cardbox_content .small {
        margin-bottom: 20px;
    }

    .prices_background .vertical_dots {
        transform: translate(-30px, -35px);
    }

    .label_price {
        height: 23px;
        top: 15px;
        width: 130px;
    }

}
.introduction_background {
  width: 100%;
  background: linear-gradient(#eaf7fa, #FFFFFF);
}


.payment_background{
  border-top-right-radius: 900px 60px;
}

.managment_service {
  background: linear-gradient(#E5F1F7, #FFFFFF);
  border-top-right-radius: 900px 60px;
}

.section_subtitle {
  font-size: 53px;
  color: #19191B;
}

.section_description {
  font-size: 18px;
  line-height: 31px;
  color: #696871;
}

.screen {
  width: 280px;
  height: 560px;
}

.service_title {
  font-size: 26px;
  color: #151B46;
  font-weight: 500;
}

.note {
  margin-top: 40px;
  font-style: italic;
  font-size: 16px;
  color: #017D9B;
  max-width: 620px;
  letter-spacing: .5px;
  line-height: 30px;
}

.show_button {
  border-radius: 12px !important;
  height: 45px;
  color: #151C45 !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  width: 230px;
}

.border_button {
  border: solid 1px #2C3871 !important;
}

.light_button {
  width: 240px;
  background-color: #DFE6F0 !important;
}


@media (max-width: 768px) {
  .payment_background {
    border-top-left-radius: 0px 0px;
    border-top-right-radius: 0px 0px;
  }

  .managment_service {
    border-top-right-radius: 0px 0px;
  }
  

  .screen {
    width: 260px;
    height: 520px;
  }

  .section_subtitle {
    font-size: 30px;
    margin-top: 0px;
    line-height: 38px;
    text-align: center;
  }

  .section_description {
    font-size: 17px;
    text-align: center;
    line-height: 30px;
    color: #000000;
  }

  .show_button {
    margin-bottom: 20px !important;
  }

  .note {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    margin-top: 10px;
  }

}
.collapse_wrap {
  margin-bottom: 25px;
}

.collapse_box {
  background-color: #F5F7FA;
  width: 340px;
  height: 85px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #DDDDDD;
  display: flex;
  cursor: pointer;
}

.collapse_wrap .open_box {
  background-color: #FFFFFF;
  border-radius: 10px;
}

.main_box {
  margin-left: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.main_box>div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.collapse_icon {
  border-radius: 50%;
  background-color: #E7ECF2;
  padding: 10px;
  width: 35px;
  height: 35px;
}

.open_box .collapse_icon {
  background-color: #F4F6F8;
}

.collapse_icon img {
  width: 35px;
}

.main_box_title {
  font-size: 18px;
  color: #19191B;
  margin-left: 12px;
}

.box_description {
  font-size: 17px;
  color: #707070;
  max-width: 303px;
  margin: auto;
  margin-top: 17px;
}

.box_description {
  line-height: 28px;
}

.box_control {
  display: none;
}

@media (max-width: 768px) {
  .collapse_box .box {
    width: 306px;
    height: 90px;
    margin-left: 0px;
    margin-right: 0px;
    cursor: auto;
  }

  .collapse_box {
    /* background-color: #FFFFFF; */
    width: 306px;
    height: 90px;
    margin-bottom: 10px;
  }

  /* .open_box {
    height: 239px;
    position: absolute;
    box-shadow: 0px 52px 54px #DDDDDD;
  } */

  .box_description {
    max-width: 260px;
    /* margin: auto; */
  }

  .main_box {
    margin-left: 15px;
  }

  .box_control {
    display: block;
    width: 25px;
    margin-right: 10px;
  }

  .collapse_wrap .open {
    width: 306px;
    height: auto;
    display: flex !important;
    flex-direction: column;
    text-align: center;
    transition: height .5s ease-out;
  }
}